import React from "react";

import { Pane, Spinner } from 'evergreen-ui';

export default function CustomSpinner ({ height = 400 }) {
  return (
    <Pane display="flex" alignItems="center" justifyContent="center" height={height}>
      <Spinner />
    </Pane>
  )
}