import React from 'react';

import {siteName} from '../settings';


const logosPath = '/img/logo';

const Logo = ({width = 160, height = 160}) => (
    <picture >
        <source type="image/webp" srcset={`${logosPath}.webp`}  width={width} height={height} />
        <source type="image/png" srcset={`${logosPath}.png`}  width={width} height={height} />
        <img src={`${logosPath}.png`} alt={`${siteName} Logo`} width={width} height={height}/>
    </picture>
)

export default Logo;
