import React from 'react';
import { Pane } from 'evergreen-ui';

// Stylesheets
import './App.css';

// Custom components
import HomeTabs from './HomeTabs';
import Logo from '../common/Logo';
import Footer from '../common/Footer';


export default function App () {
  return (
    <Pane>
      <Pane
        className="container"
        elevation={1}
      >
        <Pane className="logo"><Logo /></Pane>
        <HomeTabs className="main" />
        <Pane className="footer" paddingBottom={5}><Footer /></Pane>
      </Pane>
    </Pane>
  )
};
