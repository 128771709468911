import React, { lazy, Suspense } from "react";
import { Pane, Tab, TabNavigation } from 'evergreen-ui';

import {
  HashRouter as Router,
  Switch,
  Route,
  useRouteMatch, Redirect
} from "react-router-dom";

// Custom components

import Spinner from '../common/Spinner';

const About = lazy(() => import('../about/About'));
const Projects = lazy(() => import('../projects/Projects'));
const Articles = lazy(() => import('../articles/Articles'));

const tabs = [
  { uri: '#', label: 'About' },
  { uri: '#projects', label: 'Projects' },
  { uri: '#articles', label: 'Articles' }
]

function TabLink({ uri, label }) {
  let match = useRouteMatch({
    path: uri.replace('#', '/'),
    exact: true
  });

  return (
      <Tab
        style={{color: "white"}} height="2em" key={label} is="a"
        href={uri} id={label} isSelected={Boolean(match)}
      >
        {label}
      </Tab>
    )
}

const HomeTabs = () => {
  return (
    <Router hashType="noslash">
      <Pane>
        <TabNavigation marginTop={24} marginBottom={16} flexBasis={240} marginRight={24}>
          {tabs.map(tab => <TabLink key={tab.label} {...tab} />)}
        </TabNavigation>
        <Pane padding={16} background="tint1" flex="1">
          <Switch>
            <Route exact path="/">
              <Suspense fallback={<Spinner />}>
                <About />
              </Suspense>
            </Route>
            <Route exact path="/projects">
              <Suspense fallback={<Spinner />}>
                <Projects />
              </Suspense>
            </Route>
            <Route exact path="/articles">
              <Suspense fallback={<Spinner />}>
                <Articles />
              </Suspense>
            </Route>
            <Redirect from="*" to="/" />
          </Switch>
        </Pane>
      </Pane>
    </Router>
  )
}

export default HomeTabs