import React from 'react';
import { Pane, Text } from 'evergreen-ui';

import {siteName} from '../settings';


const Footer = () => (
  <Pane className="footer">
    <Text color="white">© {siteName}, 2019 – {(new Date()).getFullYear()}</Text>
  </Pane>
)

export default Footer;
